<template>
  <div class="syj_login-black">
    <div class="syj_page-oneeight">
      <div class="syj_enter-login-content">
        <div class="apilogin">
          <span @click="tabOpen" :class="isOpentab == true ? 'blold':''">
           飞书登录
          </span>
          |
          <span @click="tabOpending" :class="isOpendingdingtab == true ? 'blold':''">
            钉钉登录<label>(客印)</label>
          </span>
        </div>
        
        <template v-if="device !== 'phone'">
          <div>
            <div class="qrcode">
              <div class="feishu-code" id="jennylogin_container" v-show="isOpentab"></div>
              <ddinglogin-info v-if="isOpendingdingtab" v-show="isOpendingdingtab"></ddinglogin-info>
            </div>
          </div>
          <div class="tips" v-if="isOpentab">
            <i class="icon feishu"></i>
            请使用飞书扫码
          </div>
          <div class="tips" v-if="isOpendingdingtab">
            <i class="icon dingding"></i>
            请使用钉钉扫码
          </div>
        </template>
        <template v-else>
          <button
              class="login-btn feishu-btn"
              v-if="isOpentab"
              @click="feishuLoginBtn"
          >
            <i class="icon feishu"></i>
            飞书登录
          </button>
          
          <button
              class="login-btn feishu-btn"
              v-if="isOpendingdingtab"
              @click="ddLoginBtn"
          >
            <i class="icon dingding"></i>
            钉钉登录
          </button>
        </template>
      </div>
    </div>
  </div>
</template>
<script src="https://lf-package-cn.feishucdn.com/obj/feishu-static/lark/passport/qrcode/LarkSSOSDKWebQRCode-1.0.3.js">
</script>
<script>
import maiyuanRequest from "../units/maiyaunRuquest.js";
import ddinglogin from "@/components/ddinglogin";
import router from "@/router";
import {getOS} from '@/units/common'
import {getToken, setToken} from "../units/token";

export default {
  name: "login",
  data() {
    return {
      device: null,
      fontblod: 0,
      isOpentab: true,
      client_id: null,
      redirect_uri: null,
      isOpendingdingtab: false,
      loginConfig: null,
    };
  },
  components: {
    "ddinglogin-info": ddinglogin
  },
  methods: {
    tabOpen() {
      this.isOpentab = true
      this.isOpendingdingtab = false
    },
    tabOpending() {
      this.isOpentab = false
      this.isOpendingdingtab = true
    },
    homeBlack: function () {
      this.$router.push("/");
    },
    getlogincode(code, state) {
      maiyuanRequest({
        method: "post",
        url: "login",
        data: {code, state},
      }).then((res) => {
        let response = res.data;
        // console.table("aksjhdsk",res)
        if (response.status == 400) {
          this.$toast.show(response.msg, 10000);
          return;
        }
        if (response.status == 200) {
          if (response.data.token) {
            setToken(response.data.token);
            router.push("/home");
          }
        }
      });
    },
    ddgetlogincode(code, state) {
      maiyuanRequest({
        method: "post",
        url: "dlogin",
        data: {code, state},
      }).then((res) => {
        let response = res.data;
        if (response.status == 400) {
          this.$toast.show(response.msg, 10000);
          return;
        }
        if (response.status == 200) {
          if (response.data.token) {
            setToken(response.data.token);
            router.push("/home");
          }
        }
        
      });
    },
    
    feishuLoginBtn() {
      // let REDIRECT_URI = 'http://10.23.12.159:8080/login'
      let REDIRECT_URI = this.redirect_uri
      let STATE = 'STATE'
      let app_id = this.client_id
      window.open(`https://open.feishu.cn/open-apis/authen/v1/index?redirect_uri=${REDIRECT_URI}&app_id=${app_id}&state=${STATE}`, '_self')
    },
    
    ddLoginBtn() {
      // let REDIRECT_URI = 'http://10.23.12.159:8080/login'
      let REDIRECT_URI = this.redirect_uri
      let STATE = 'dd'
      let app_id = this.loginConfig.dd_appid
      window.open(`https://oapi.dingtalk.com/connect/oauth2/sns_authorize?appid=${app_id}&response_type=code&scope=snsapi_login&state=${STATE}&redirect_uri=${REDIRECT_URI}`, '_self')
    }
  },
  created() {
    let that = this;
    this.device = getOS()
    console.log(this.device)
    maiyuanRequest({
      method: "get",
      url: "get_flybook",
      //  data:{ "appid": "cli_a3a2b647ccb9d00d", "redirect_uri": "https://guess.maiyuan.online/home"},
      //  params:{test:"111",hello:"world"} 网址请求参数 get 用的
    }).then((res) => {
      let response = res.data;
      that.client_id = response.data.appid;
      that.redirect_uri = response.data.redirect_uri;
      this.loginConfig = response.data
      // that.rule = response.data.rule;
      //  console.log("data jenny",response )
      const client_id = this.client_id; // 这两个是飞书扫码登录APP的AppID和Secret，可以在飞书工作台中创建扫码登陆应用
      const redirect_uri = this.redirect_uri;
      // const client_id = "cli_a3a2b647ccb9d00d"; // 这两个是飞书扫码登录APP的AppID和Secret，可以在飞书工作台中创建扫码登陆应用
      // const redirect_uri = "https://guess.maiyuan.online/home";
      
      console.log("client_id", client_id);
      var QRLoginObj = QRLogin({
        id: "jennylogin_container",
        goto:
            "https://www.feishu.cn/suite/passport/oauth/authorize?client_id=" +
            client_id +
            "&redirect_uri=" +
            redirect_uri +
            "&response_type=code&state=STATE",
        style: "border:none;background-color:#FFFFFF;overflow: hidden;  ",
        width: "260",
        height: "260",
        scrolling: "no",
      });
      var handleMessage = function (event) {
        if (QRLoginObj.matchOrigin(event.origin) && QRLoginObj.matchData(event.data)) {
          var loginTmpCode = event.data.tmp_code;
          // 在授权页面地址上拼接上参数 tmp_code，并跳转
          window.location.href = `https://www.feishu.cn/suite/passport/oauth/authorize?client_id=${client_id}&redirect_uri=${redirect_uri}&response_type=code&state=STATE&tmp_code=${loginTmpCode}`;
        }
      };
      
      if (typeof window.addEventListener != "undefined") {
        window.addEventListener("message", handleMessage, false);
      } else if (typeof window.attachEvent != "undefined") {
        window.attachEvent("onmessage", handleMessage);
      }
    });
  },
  mounted() {
    const { code, state} = this.$route.query
    if (getToken() != '') {
      router.push("/home");
    } else {
      if (code && state) {
        if (state == "STATE") {
          this.getlogincode(code, state);
        } else if (state == "dd") {
          this.ddgetlogincode(code, state)
        }
      }
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less">
//#jennylogin_container{margin-top:-15px}

.syj_login-black {
  .syj_page-oneeight {
    max-width: 1038px;
    margin: auto;
    position: relative;
    height: 100vh;
    top: 50%;
    transform: translatey(-50%);
  }
  
  background: url(https://maiyuan-zhusun.oss-cn-hangzhou.aliyuncs.com/upload/20241108-100733.jpeg) no-repeat no-repeat center / cover;
  height: 100vh;
  position: relative;
  background-position: top center;
  background-size: cover;
  width: 100%;
  
  .syj_enter-login-content {
    width: 415px;
    position: absolute;
    top: 44%;
    left: -21%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    flex-wrap: wrap;
    
    .apilogin {
      width: 100%;
      color: #fff;
      font-weight: 600;
      margin-bottom: 20px;
      
      span {
        color: #fff;
        padding: 0 10px;
        cursor: pointer;
        letter-spacing: 4px;
        
        &.blold {
          font-weight: 600;
          //color: #eaaa62;
        }
      }
    }
    
    div {
      &::-webkit-scrollbar {
        overflow: hidden;
      }
      
      // border: 5px solid #fff;
      // width: 260px;
      // height: 260px;
      // position: absolute;
      // right: 90px;
      // @media (max-width: 1700px) {
      //   right: 130px;
      // }
    }
    
    p {
      margin: 0;
      color: rgba(255, 255, 255, 0.8);
      font-weight: lighter;
      letter-spacing: 6px;
    }
  }
}

.qrcode {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 185px;
  height: 185px;
  padding: 10px;
  box-sizing: border-box;
  border-radius: 10px;
  overflow: hidden;
  background-color: #f6f6f6;
  
  .feishu-code {
    display: flex;
    justify-content: center;
    align-items: center;
    transform: scale(0.68)
  }
}

</style>

<style scoped lang="less">
@media (max-width: 480px) {
  * {
    -webkit-tap-highlight-color: transparent;
  }
  .syj_enter-login-content {
    width: 100%;
    left: 0;
  }
}

.login-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 300px;
  height: 46px;
  border-radius: 50px;
  margin-top: 20px;
  background-color: #fff;
  color: #333;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  border: none;
}

.tips {
  display: flex;
  align-items: center;
  margin-top: 6px;
  font-size: 14px;
  color: #fff;
}

.icon {
  width: 32px;
  height: 32px;
  margin-right: 4px;
  
  &.feishu {
    background: url(../assets/images/feishu.png) no-repeat center;
  }
  
  &.dingding {
    background: url(../assets/images/dingding.png) no-repeat center;
    background-size: 26px 26px;
  }
}
</style>
