<template>
  <div id="login_container" style="transform: scale(0.8)"></div>
</template>
<script src="https://g.alicdn.com/dingding/dinglogin/0.0.5/ddLogin.js"></script>
<script>
import maiyuanRequest from "../units/maiyaunRuquest.js";
import router from "@/router";
import {setToken} from "../units/token";

export default {
  name: "dlogin",
  data() {
    return {
      ddappid: null,
      ddredirect_uri: null,
      ddappSecret: null
    };
  },
  methods: {
    GetQueryString(name) {
      var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
      var r = window.location.search.substr(1).match(reg); //获取url中"?"符后的字符串并正则匹配
      var context = "";
      if (r != null) context = r[2];
      reg = null;
      r = null;
      return context == null || context == "" || context == "undefined"
          ? ""
          : context;
    },
    ddgetlogincode(code, state) {
      maiyuanRequest({
        method: "post",
        url: "dlogin",
        data: {code, state},
      }).then((res) => {
        let response = res.data;
        if (response.status == 400) {
          this.$toast.show(response.msg, 10000);
          return;
        }
        if (response.status == 200) {
          if (response.data.token) {
            setToken(response.data.token);
            router.push("/home");
          }
        }
        
      });
    }
  },
  
  created() {
    console.log('钉钉渲染')
    let that = this;
    maiyuanRequest({
      method: "get",
      url: "get_flybook",
      // data:{ "appid": "dingon3omch0azgrph7i", "redirect_uri": "https://guess.maiyuan.online/home"},
    }).then((res) => {
      
      let response = res.data;
      that.ddappid = response.data.dd_appid;
      that.ddredirect_uri = response.data.dd_redirect_uri;
      that.ddappSecret = response.data.dd_appSecret
      let appid = this.ddappid // 这两个是钉钉扫码登录APP的AppID和Secret，可以在钉钉工作台中创建扫码登陆应用
      console.log("222222222222222", appid)
      const appSecret = this.ddappSecret
      console.log("222222222222222", appSecret)
      // 重定向地址，因为vue用的hash，所以网址后面是 ，扫码之后要跳转的网址，用于扫码之后的回调验证
      let redirect = this.ddredirect_uri  //
      // 官网给的跳转连接格式， 钉钉的登录链接接口，这个是固定的
      console.log("redirect", redirect)
      // let httpUrl = `https://oapi.dingtalk.com/connect/oauth2/sns_authorize?appid=${appid}&response_type=code&scope=snsapi_login&state=dd&redirect_uri=${redirect}`
      const httpUrl = encodeURIComponent(`https://oapi.dingtalk.com/connect/oauth2/sns_authorize?appid=${appid}&response_type=code&scope=snsapi_login&state=dd&redirect_uri=${redirect}`)
      // 钉钉登录，参数文档中那些其他项就不展示了，按照文档自己配置就可以
      console.log("redirect", redirect);
      console.log("httpUrl", httpUrl)
      // 钉钉家的登录库，用这个可以直接生成二维码，无需其他操作
      const obj = DDLogin({
        id: 'login_container',
        goto: httpUrl,
        style: "border:none;background-color:#FFFFFF;",
        width: "240",
        height: "260"
      })
      // 监听钉钉扫码成功，通过临时授权码跳转到指定连接
      var handleMessage = function (event) {
        var origin = event.origin;
        console.log('event', event);
        console.log("origin", event.origin);
        if (origin == "https://login.dingtalk.com") {
          //判断是否来自ddLogin扫码事件。
          var loginTmpCode = event.data;
          //获取到loginTmpCode后就可以在这里构造跳转链接进行跳转了
          console.log("loginTmpCode", loginTmpCode);
          //https://oapi.dingtalk.com/connect/oauth2/sns_authorize?appid=APPID&response_type=code&scope=snsapi_login&state=STATE&redirect_uri=REDIRECT_URI&loginTmpCode=loginTmpCode
          const url = `https://oapi.dingtalk.com/connect/oauth2/sns_authorize?appid=${appid}&response_type=code&scope=snsapi_login&state=dd&redirect_uri=${httpUrl}&loginTmpCode=${loginTmpCode}`;
          // 这边直接打开拼接好的连接，去获取临时登录码，回来地址在下面
          location.href = url
        }
      };
      if (typeof window.addEventListener != 'undefined') {
        window.addEventListener('message', handleMessage, false);
      } else if (typeof window.attachEvent != 'undefined') {
        window.attachEvent('onmessage', handleMessage);
      }
      
    });
  },
  mounted() {
    let code = this.GetQueryString("code");
    let state = this.GetQueryString("state");
    if (code && state) {
      if (state == "dd") {
        this.ddgetlogincode(code, state);
      }
    }
  },
};
</script>


<style lang="less" scoped>
#login_container {
  margin-top: -15px
}

</style>
